.Button {
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: white !important;
  background-color: rgb(46, 105, 178) !important;
  text-transform: capitalize;
  font-weight: bold;
  font-family: "Hurme Geometric Sans 3";
  margin-right: 10px !important;
  padding: 8px 14px;
  font-size: 14px;
  box-shadow: 0px 3px 1px -0.5px rgba(0, 0, 0, 0.2),
    0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px !important;
}
.ButtonLinkedin {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.auth .Field {
  margin-bottom: 20px !important;
  font-size: 24px !important;
  width: 50vw;
}
.auth .Field input {
  font-size: 24px !important;
  font-weight: bolder;
  margin-top: 20px !important;

  color: rgb(46, 105, 178) !important;
}
.Field.passwordhint input {
  padding-right: 40px;
}
.MuiInputBase-root {
  line-height: unset !important;
}
.auth .Field label {
  font-size: 18px !important;
  color: rgb(46, 105, 178) !important;
}

.auth-mobile .Field {
  margin-bottom: 20px !important;
  font-size: 24px !important;
  width: 50vw;
}

.auth-mobile .Field input {
  font-size: 16px !important;
  font-weight: bolder;
  margin-top: 20px !important;

  color: rgb(46, 105, 178) !important;
}

.auth-mobile .Field label {
  font-size: 14px !important;
  color: rgb(46, 105, 178) !important;
  width: 200px;
}

.ButtonGoogle {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
  display: inline;
  padding: 0px !important;
  margin-left: 20px !important;
}
.ButtonGoogle span {
  padding: 0px !important;
  display: flex;
}
.contact .Field input {
  font-size: 18px !important;
  color: #2d307a !important;
}
.contact .MuiInput-underline::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0.5px solid #2d307a !important;
  border-bottom-color: #2d307a;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  pointer-events: none;
}
.contact .Field textarea {
  /* padding-top: 10px; */
  font-size: 18px !important;
  font-weight: bolder;
  margin-top: 20px !important;
  color: #2d307a !important;
}
.contact .MuiInput-underline::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0.5px solid #2d307a !important;
  border-bottom-color: #2d307a;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  pointer-events: none;
}

.contact .Field label {
  font-size: 18px !important;
  color: #2d307a !important;
}
.recruiter .Field label {
  color: #2d307a !important;
}
.recruiter .Field input {
  font-size: 24px !important;
  color: #2d307a !important;
}
.recruiter .MuiInput-underline::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1.5px solid #2d307a !important;
  border-bottom-color: #2d307a;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  pointer-events: none;
}
.recruiter .Field textarea {
  font-size: 24px !important;
  font-weight: bolder;
  margin-top: 20px !important;
  color: #2d307a !important;
}
.recruiter .MuiInput-underline::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0.5px solid #2d307a;
  border-bottom-color: #2d307a;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  pointer-events: none;
}
.recruiter .MuiInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #f44336;
}
.Submit-Btn {
  display: none;
}
.Header-Step .MuiStepLabel-label.MuiStepLabel-completed {
  color: #ccc !important;
}
.Custom-Dialog .MuiPaper-root {
  background-color: transparent !important;
  box-shadow: none !important;
}
.Unity-Container {
  background-color: transparent !important;
}
.Profile .MuiFormLabel-filled {
  color: rgb(46, 105, 178) !important;
}
.Profile.Field {
  width: 520px !important;
  font-size: 24px !important;
}
.Profile.custom-date label {
  font-weight: lighter;
}
.Profile.Field input {
  margin-top: 5px !important;
}
.Profile-cfm-cv .MuiFormLabel-filled {
  color: rgb(46, 105, 178) !important;
}
.Profile-cfm-cv.Field {
  width: 520px !important;
  font-size: 20px !important;
}
.Profile-cfm-cv.custom-date label {
  font-weight: lighter;
}
.Profile-cfm-cv.Field input {
  margin-top: 5px !important;
  font-size: 20px !important;
}
.validate .Field input {
  font-size: 30.5px !important;
  font-weight: bolder;
  margin-top: 20px !important;
  text-align: center;
  color: rgb(46, 105, 178) !important;
}
.validate .MuiFormHelperText-root.Mui-error {
  color: #f44336;
  font-size: 18px;
  text-align: center;
}
.validate-mobile .Field input {
  font-size: 16px !important;
  font-weight: bolder;
  margin-top: 20px !important;
  color: rgb(46, 105, 178) !important;
}
.validate-mobile .Field label {
  font-size: 14px !important;
  color: rgb(46, 105, 178) !important;
}
.validate-mobile .MuiFormHelperText-root.Mui-error {
  color: #f44336;
  font-size: 16px;
  text-align: center;
}
.select-with-placeholder div span {
  color: rgba(46, 105, 178, 0.3) !important;
}
.auth .Field.Single {
  padding-top: 20px;
  /* margin-top: 20px; */
}
.error .react-date-picker__wrapper {
  border-bottom-color: #f44336 !important;
}
.BodyPopup a {
  color: rgb(46, 105, 178) !important;
  font-weight: bold;
}
