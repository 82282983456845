.react-html5-camera-photo #container-circles {
  display: none;
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  width: 200px;
}
@font-face {
  font-family: "VideoJS";
  src: url("https://vjs.zencdn.net/f/1/vjs.eot");
  src: url("https://vjs.zencdn.net/f/1/vjs.eot?#iefix")
      format("embedded-opentype"),
    url("https://vjs.zencdn.net/f/1/vjs.woff") format("woff"),
    url("https://vjs.zencdn.net/f/1/vjs.ttf") format("truetype");
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
  content: "\f103";
  font-family: "VideoJS";
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
  content: "\f107";
  font-family: "VideoJS";
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: "\f101";
  font-family: "VideoJS";
}

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
  content: "\f121";
  font-family: "VideoJS";
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
  content: "\f108";
  font-family: "VideoJS";
}
.video-js .vjs-volume-level:before,
.vjs-play-progress::before {
  content: "\f111";
  font-family: "VideoJS";
}

.video-js .vjs-big-play-button {
  top: 42%;
  left: 42%;
}
.vjs-icon-play,
.video-js .vjs-play-control .vjs-icon-placeholder,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
  background-color: #2f6ab294;
  border-radius: 8px;
}
.video-js .vjs-control-bar {
  background-color: rgb(47 105 178 / 37%);
}
.vjs-live-display {
  display: none;
}
.vjs-picture-in-picture-control {
  display: none !important;
}

.video-js .vjs-play-progress:before {
  top: -0.3em;
}
