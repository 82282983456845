.full-calendar .rbc-header {
  padding: 10px;
  opacity: 0.5;
}
.full-calendar .rbc-month-view {
  border: none;
}
.rbc-header + .rbc-header {
  border-left: none !important;
}
.rbc-day-bg + .rbc-day-bg {
  border-bottom: none !important;
}
.rbc-month-row + .rbc-month-row {
  border-top: none;
}
.rbc-month-row {
  border: 1px solid #afbfe5 !important;
  margin-top: -1px;
}

.rbc-header {
  border-bottom: none !important;
}
.full-calendar .rbc-day-bg {
  background-color: white;
  padding: 10px;
  color: #2e69b2;
}
.full-calendar .rbc-off-range-bg {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: transparent;
}
.full-calendar .rbc-date-cell.rbc-off-range {
  color: transparent;
}
.full-calendar .rbc-date-cell,
.full-calendar .rbc-toolbar-label {
  color: #2e69b2;
}
.full-calendar .rbc-header:nth-last-of-type( 7),
.full-calendar .rbc-row .rbc-date-cell:nth-last-of-type(7) {
  color: #cd5a4d91;
}
.rbc-date-cell.rbc-off-range:nth-last-of-type(7) {
  color: transparent !important;
}
.full-calendar .rbc-row-segment {
  position: relative;
  height: 64px;
}
.full-calendar .rbc-event {
  position: absolute;
  border-radius: 0px;
  bottom: 0px;
  padding: 8px;
  width: 98%;
  background-color: #00a380;
}
.full-calendar .rbc-event-content {
  font-size: 13px;
}
.rbc-event.rbc-selected {
  background-color: #138068 !important;
}
.full-calendar .rbc-btn-group {
  display: none;
}
.full-calendar .rbc-toolbar {
  margin-bottom: 20px;
  position: relative;
}
.full-calendar .back-button,
.full-calendar .next-button {
  position: absolute;
  cursor: pointer;
  width: 47px;
  padding: 5px;
}
.back-button {
  margin-right: 180px;
  /* left: 0px; */
}
.next-button {
  margin-left: 180px;
}
.rbc-toolbar {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #afbfe5 !important;
}
