.user .MuiTimelineItem-missingOppositeContent:before {
  flex: none;
  padding: 0;
}
.user .MuiTimelineDot-root {
  margin: 0;
}
.user .MuiTimelineItem-root .MuiTimelineSeparator-root span {
  background-color: rgb(46, 105, 178);
}
.user .MuiTimelineContent-root {
  padding: 0px 16px;
}
.user .MuiTimelineContent-root p {
  margin: 0;
}
p.title {
  font-size: 18px;
  padding: 0px 0px 5px 0px;
}
p.subtitle {
  font-size: 18px;
  font-weight: lighter;
  margin-bottom: 30px !important;
}

.EditCV .Field input {
  font-size: 18px !important;
}
.EditCV .Field span {
  font-size: 18px !important;
}
.Field.work-ready {
  width: 200px !important;
}
.Field.work-ready input {
  font-size: 18px !important;
  font-weight: normal;
}
.Field.work-ready span {
  font-size: 18px !important;
  font-weight: normal;
}
.Tab-preview div.MuiBox-root {
  padding: 24px 0px !important;
}
.dialog-additional
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  font-size: 18px !important;
}
.dialog-additional .Field label {
  font-size: 14px !important;
  text-transform: none !important;
}
.dialog-additional .Field input {
  font-size: 18px !important;
}
.dialog-additional .DateWorkExp.custom-date label {
  font-size: 12px !important;
  text-transform: none !important;
}
.dialog-additional .react-date-picker__inputGroup {
  font-size: 16px !important;
}
.dialog-additional .Field {
  width: 100% !important;
}
