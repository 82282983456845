@font-face {
  font-family: "Hurme Geometric Sans 3";
  src: url("HurmeGeometricSans3-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Hurme Geometric Sans 3";
  src: url("HurmeGeometricSans3-Black.otf");
  font-weight: bolder;
}
@font-face {
  font-family: "Hurme Geometric Sans 3";
  src: url("HurmeGeometricSans3.otf");
  font-weight: 100;
}

@font-face {
  font-family: "Hurme Geometric Sans 3";
  src: url("HurmeGeometricSans3-SemiBold.otf");
  font-weight: normal;
}

body,
p,
a,
div,
h1,
h2,
h3,
h4,
h5,
h6,
span,
li {
  font-family: "Hurme Geometric Sans 3" !important;
}

a {
  text-decoration: none;
}
