.freetext-progress {
  background-color: rgb(212, 213, 220) !important;
  border-radius: 4px;
  height: 10px !important;
}
.progress {
  background-color: rgb(212, 213, 220) !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 10px !important;
}
.freetext-progress .MuiLinearProgress-bar {
  background-color: rgb(46, 105, 178) !important;
  border-radius: 4px;
}
.Biodata.freetext-progress .MuiLinearProgress-bar {
  background-color: rgb(81, 200, 244) !important;
  border-radius: 4px;
}
.freetext-progress {
  background-color: rgb(212, 213, 220) !important;
  border-radius: 4px;
  height: 10px !important;
}
.VideoInterview.progress .MuiLinearProgress-bar {
  background-color: rgb(81, 200, 244) !important;
  border-radius: 4px;
}

.about-you .freetext-progress {
  background-color: rgb(212, 213, 220) !important;
  border-radius: 20px;
  height: 10px !important;
}
.about-you .freetext-progress .MuiLinearProgress-bar {
  background-color: rgb(46, 105, 178) !important;
  border-radius: 20px;
}

.answer .MuiFilledInput-underline {
  border: none !important;
}
.answer .MuiFilledInput-root {
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
}
.answer .MuiFilledInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  pointer-events: none;
  border: none !important;
}
.answer .MuiFilledInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  pointer-events: none;
  border: none !important;
}
.answer .MuiFilledInput-input {
  padding: 20px;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
}
.pulse {
  animation: pulse 1s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(46, 105, 178, 0.7);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(46, 105, 178, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(46, 105, 178, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(46, 105, 178, 0.7);
    box-shadow: 0 0 0 0 rgba(46, 105, 178, 0.7);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(46, 105, 178, 0);
    box-shadow: 0 0 0 10px rgba(46, 105, 178, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(46, 105, 178, 0);
    box-shadow: 0 0 0 0 rgba(46, 105, 178, 0);
  }
}
