.react-datepicker__header {
  background-color: #2e69b2;
  border-bottom: 1px solid #2e69b2;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: white;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0px;
}
.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day-name:last-child,
.react-datepicker__day--weekend:last-child {
  color: rgb(187, 43, 43);
}
.react-datepicker__navigation--next {
  border-left-color: white;
}
.react-datepicker__navigation--previous {
  border-right-color: white;
}
.react-datepicker__input-container input.Field.work-ready {
  font-size: 18px !important;
  margin-top: 20px;
}
