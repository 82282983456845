.MuiInput-underline::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #3B69AD !important;
    border-bottom-color: #3B69AD;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    pointer-events: none;
}

.MuiInputLabel-animated {
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    text-transform: uppercase;
}

.MuiIconButton-root {
    padding: 0 !important;
    border: none;
}

.MuiIconButton-label {
    border: none;
}