table.Candidates {
  width: 100%;
  padding: 0px 100px 0px;
}

.Candidates thead div {
  width: 100%;
  display: flex !important;
  flex-direction: column;
}

.Candidates tr:after {
  display: block;
  visibility: hidden;
  clear: both;
}

.Candidates tbody {
  height: 10px;
  overflow-x: auto;
}

.Candidates tbody td,
.Candidates thead th {
  /* width: 19.2%; */
  float: left;
}
