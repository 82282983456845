.interview-schedule__indicator-separator {
  background-color: transparent !important;
}

.interview-schedule__indicator {
  color: #2e69b2 !important;
}

.interview-schedule__control {
  background-color: #F8F8F8 !important;
  border: 1px solid #DFE5F4 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.interview-schedule__placeholder {
  font-size: 16px !important;
  color: rgba(46, 105, 178, 0.8) !important;
}

.interview-schedule__single-value {
  color: #2E69B2 !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  margin-left: -4vh;
  margin-top: 2vh;
}