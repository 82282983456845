.recruiter
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input
  span {
  padding: 10px 5px;
  font-weight: 200;
}
.recruiter
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px 15px;
  color: rgba(46, 105, 178, 1);
}
.recruiter .MuiInputBase-root.MuiOutlinedInput-root.select-with-placeholder {
  height: 43px;
  /* border: 0.5px solid rgba(0, 0, 0, 0.3); */
  /* box-shadow: none; */
}
.recruiter .Search:hover {
  border: 0.5px solid rgba(0, 0, 0, 1) !important;
}

.recruiter .Search input::placeholder {
  font-weight: 200;
  color: rgba(46, 105, 178, 0.3) !important;
}
.recruiter .Search input {
  color: rgba(46, 105, 178, 1) !important;
}
.recruiter table.item {
  margin-bottom: 20px;
}
.recruiter table th {
  vertical-align: baseline;
  font-weight: normal;
}
.recruiter table th span,
table td span {
  font-weight: 100;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}
_::-webkit-full-page-media,
_:future,
:root .safari_only,
.recruiter table th span,
table td span {
  font-weight: 100;
  padding-top: 0px;
  padding-bottom: 10px;
  display: block;
}
.recruiter .More {
  min-width: 0px;
}
.ButtonNoPadding span {
  padding: 0px;
}
.MuiButton-root.ButtonNoPadding {
  min-width: 0px;
}
.recruiter .More span {
  padding: 0px;
}
.Status .MuiButton-label {
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
}
.RecruiterUserMenu ul {
  padding: 0px;
}
.RecruiterUserMenu li {
  border-bottom: 0.5px solid #8080802e;
  padding: 10px 25px;
}
.RecruiterVacancy .Field input {
  font-size: 24px !important;
}

.contact-recruiter .Field input {
  font-size: 18px !important;
  color: rgb(46, 105, 178) !important;
}
.contact-recruiter .MuiInput-underline::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0.5px solid rgb(46, 105, 178) !important;
  border-bottom-color: rgb(46, 105, 178);
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  pointer-events: none;
}
.contact-recruiter .Field textarea {
  /* padding-top: 10px; */
  font-size: 18px !important;
  font-weight: bolder;
  margin-top: 20px !important;
  color: rgb(46, 105, 178) !important;
}
.contact-recruiter .MuiInput-underline::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0.5px solid rgb(46, 105, 178) !important;
  border-bottom-color: rgb(46, 105, 178);
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  pointer-events: none;
}

.contact-recruiter .Field label {
  font-size: 18px !important;
  color: rgb(46, 105, 178) !important;
}
/* modify select2 css by Josh */
.create-vacancy .css-yk16xz-control,
.create-vacancy .css-yk16xz-control:hover,
.edit-vacancy .css-yk16xz-control,
.edit-vacancy .css-yk16xz-control:hover {
  border-width: 0px 0px 2px 0px;
  border-radius: 0px;
  border-color: #2e69b2;
}

.create-vacancy .css-1wy0on6,
.edit-vacancy .css-1wy0on6 {
  display: none;
}

.create-vacancy .css-1wa3eu0-placeholder {
  font-size: 24px;
  font-weight: bold;
  color: rgba(46, 105, 178, 0.2);
}

.edit-vacancy .css-1wa3eu0-placeholder {
  font-size: 18;
  font-weight: bold;
  color: rgba(46, 105, 178, 0.2);
}

.create-vacancy .css-1rhbuit-multiValue,
.edit-vacancy .css-1rhbuit-multiValue {
  background-color: rgba(82, 200, 244, 0.2);
  border-radius: 20px;
}

.create-vacancy .css-12jo7m5,
.edit-vacancy .css-12jo7m5 {
  font-size: 16px;
  font-weight: 600;
  color: #2e69b2;
  padding: 10px 20px;
}

.create-vacancy .css-xb97g8,
.edit-vacancy .css-xb97g8 {
  padding: 10px;
}

.create-vacancy .css-xb97g8:hover,
.edit-vacancy .css-xb97g8:hover {
  background-color: #2e69b2;
  cursor: pointer;
}

.create-vacancy .css-26l3qy-menu,
.edit-vacancy .css-26l3qy-menu {
  color: #2e69b2;
}

.create-vacancy .suggestion-button {
  background-color: rgba(82, 200, 244, 0.2);
  border-radius: 40px;
  margin: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #2e69b2;
  text-transform: none;
}

.edit-vacancy .suggestion-button {
  background-color: rgba(82, 200, 244, 0.2);
  border-radius: 40px;
  margin: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #2e69b2;
  text-transform: none;
}

.create-vacancy .suggestion-label,
.edit-vacancy .suggestion-label {
  padding: 5px 10px;
}

.create-vacancy .suggestion-image,
.edit-vacancy .suggestion-image {
  padding: 5px;
}

.create-vacancy .MuiInputBase-input.MuiInput-input {
  font-size: 24px !important;
}

.create-vacancy .css-1laao21-a11yText {
  display: block;
}

.create-vacancy .information,
.edit-vacancy .information {
  visibility: hidden;

  /* Fade in information - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.create-vacancy .information-tag:hover + .information,
.edit-vacancy .information-tag:hover + .information {
  visibility: visible;
  opacity: 1;
}

.soft-skill .question {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s;
}

.soft-skill .question-tag:hover + .question {
  visibility: visible;
  opacity: 1;
}

.edit-vacancy .MuiFormLabel-root {
  font-size: 18px !important;
  font-weight: lighter;
  text-transform: none;
}

.edit-vacancy .MuiInputBase-input.MuiInput-input {
  font-size: 18px !important;
}

.font-hurme {
  font-family: "Hurme Geometric Sans 3";
}

.MuiFormLabel-root {
  font-family: "Hurme Geometric Sans 3" !important;
}
