.MuiTabs-root.Tabs-User-Profile span {
  font-size: 18px !important;
}
.Tabs-User-Profile button.MuiTab-root {
  min-width: 235px !important;
  max-width: none;
}
.Tabs-User-Profile table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 45px;
}

.Tabs-User-Profile th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f0f3fa;
  color: #2e69b2;
}

.Tabs-User-Profile table td,
.Tabs-User-Profile table th {
  border: 1px solid #afbfe5;
  padding: 15px 10px;
}

.Tabs-User-Profile table .MuiChip-root {
  justify-content: flex-start;
  padding: 0px 10px;
}
.Tabs-User-Profile table .MuiChip-root span {
  font-weight: normal;
}
